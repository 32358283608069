<template>
  <div>
    <v-card flat width="550" class="mx-auto">
      <v-card-title class="ml-3">
        <span class="primary--text display-2 font-weight-light">Ledger</span
        ><span class="success--text display-2 font-weight-normal">5</span>
      </v-card-title>
      <v-card-title class="ml-3">
        <span class="primary--text subtitle-2 mt-n9"
          >Wealth Tracking & Planning</span
        >
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
