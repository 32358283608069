<template>
  <div>
    <ledger5Logo />
    <v-card flat width="550" class="mx-auto mt-4">
      <v-card-title>
        <span class="primary--text display-1 ml-3 mt-2 mb-2"
          >Reset password</span
        >
        <v-progress-linear
          v-if="showProgressBar"
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="2" />

            <v-col cols="8" class="mt-4">
              <v-text-field
                rounded
                v-model="email"
                :rules="emailRules"
                validate-on-blur
                prepend-icon="mdi-account-circle"
                label="Username (E-Mail)"
                required
                outlined
                dense
              />
              <div class="text-center">
                <p class="pl-8 pb-2 primary--text font-weight-medium">
                  You will receive a verification code via E-Mail to reset your
                  password.
                </p>
                <v-btn
                  rounded
                  depressed
                  color="success text--white"
                  @click="forgotPw"
                  width="304"
                  class="ml-8 mb-6"
                  >Reset Password</v-btn
                >
              </div>
            </v-col>

            <v-col cols="2" />
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import ledger5Logo from '@/components/Ledger5Logo.vue'

export default {
  name: 'ForgotPassword',

  components: {
    ledger5Logo
  },

  data() {
    return {
      showProgressBar: false,
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },

  methods: {
    async forgotPw() {
      if (this.$refs.form.validate()) {
        // validate form and enable button
        this.showProgressBar = true
        Auth.forgotPassword(this.email)
          .then(data => {
            this.showProgressBar = false
            console.log(data)
            this.$router.push({
              path: '/resetPassword',
              query: { email: this.email }
            })
          })
          .catch(err => {
            this.showProgressBar = false
            this.$refs.form.reset() // Reset form to enable the user to enter new data
            console.log(err)
            const notification = {
              type: 'error',
              message: err.message
            }
            this.$store.dispatch('addNotification', notification)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
